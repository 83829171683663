import React from "react";
import Layout from "../components/layout";
// import Image from '../components/image'
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Hero from "../components/hero";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Product from "../components/product";
import DonationFooter from "../components/donation-footer";
import ReactGA from "react-ga";

class Products extends React.Component {

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<Layout>
				<SEO title="Products" keywords={[`recommeded products`]} />
				<Navigation />

				<Hero
					header="My Products"
					subHeader="These products were created to optimize my own workflow. Hopefully they'll do the same for you. ❤"
					maxWidth={650}
				/>

				<Container style={{ padding: 20 }}>
					<Row>
						<Col xs={12} md={4} lg={3}>
							<Row>
								<Product
									name="TimeBolt"
									decription="TimeBolt is a desktop app that lets you quickly cut out the parts of your video or audio file that are below a certain volume level."
									link="https://timebolt.io"
									newTab={true}
								/>
							</Row>
							<Row>
								<Product
									name="ADHD Reader"
									decription="Improve reading attention by interacting with your text."
									link="https://adhdreader.com"
								/>
							</Row>
							<Row>
								<Product
									name="Free Text To Speech Reader"
									decription="A helpful tool for Free Text to Speech Reading"
									link="/products/free-text-to-speech-reader"
								/>
							</Row>
							<Row>
								<Product
									name="Formidable Ventures"
									decription="Formidable Ventures creates new and innovative technology business verticals for existing business houses."
									link="https://formidablevc.com"
									newTab={true}
								/>
							</Row>
						</Col>
						<Col xs={12} md={4} lg={3}>
							{/* <Row>
								<Product
									name="How To Build A Micro-SaaS side-hustle That Actually Makes Money"
									decription="A super-dense 40-page book about what you—a Programmer, Hacker or Tinkerer—should pay attention to while building a Micro-SaaS Product on your own!"
									link="/micro-saas-side-hustle"
								/>
							</Row> */}
							<Row>
								<Product
									name="opnlnk.co"
									decription="The cleanest way to convert your browser-opening links to trust-worthy, app-opening links that look similar to the originals, and open the correct page in the correct app."
									link="https://opnlnk.co"
								/>
							</Row>
							<Row>
								<Product
									name="SurpriseFlow"
									decription="Grant access to digital products, secret links, or coupons by accepting Internet Actions"
									link="https://surpriseflow.com/"
									newTab={true}
								/>
							</Row>
							<Row>
								<Product
									name="AutoPad"
									decription="Fastest way to resize and crop videos for Social Media"
									link="https://gum.co/autopad"
								/>
							</Row>
						</Col>
						<Col xs={12} md={4} lg={3}>
							<Row>
								<Product
									name="Starmer"
									decription="Geo-tag your X (Twitter) account and make it discoverable to people around you."
									link="https://starmer.app"
								/>
							</Row>
							<Row>
								<Product
									name="python-dsa"
									decription="Repository to store data structures and algorithms implemented in Python."
									link="https://github.com/graphoarty/python-dsa"
									newTab={true}
								/>
							</Row>
							<Row>
								<Product
									name="Spacer"
									decription="Keep spaces and linebreaks in Instagram Captions, Tinder Bios etc."
									link="/products/spacer"
								/>
							</Row>
						</Col>
						<Col xs={12} md={4} lg={3}>
							<Row>
								<Product
									name="CamPrompter"
									decription="CamPrompter is a front-camera-enabled Teleprompter."
									link="https://camprompter.app"
								/>
							</Row>
							<Row>
								<Product
									name="Supreme Font Generator"
									decription="Utility to Supreme Font Images"
									link="/products/supreme-logo-generator"
								/>
							</Row>
							<Row>
								<Product
									name="Generate Random Hashes"
									decription="Utility to Generate Random Hashes"
									link="/products/generate-random-hashes"
								/>
							</Row>
							<Row>
								<Product
									name="YouTube Channel | Data Stuctures and Algorithms Code Tutorial Videos"
									decription="A place where I have put up more than a hundred video teaching DS and A Code."
									link="https://youtube.com/quinstonpimenta"
									newTab={true}
								/>
							</Row>
						</Col>
					</Row>
				</Container>

				<DonationFooter ad={false}></DonationFooter>

			</Layout>
		);
	}
}

export default Products;
